/* eslint-disable @typescript-eslint/no-explicit-any */
import deepEqual from "deep-equal";
import Router, { NextRouter } from "next/router";
import { omit } from "ramda";
import { useCallback, useEffect, useRef, useState } from "react";
import { A } from "ts-toolbelt";

function queryValueAsString(
  queryKey: string,
  router: NextRouter
): string | undefined {
  const value = router.query[queryKey];
  if (typeof value === "undefined") {
    return undefined;
  }
  if (typeof value === "string") {
    return value.length ? value : undefined;
  }
  const firstValue = value[0];
  return firstValue.length ? firstValue : undefined;
}

type QueryStateItem<T> = {
  toString: (value: T) => string | undefined;
  fromString: (value: string | undefined) => T;
  customName?: string;
};

export function queryStateItem<T>(
  toString: (value: T) => string | undefined,
  fromString: (value: string | undefined) => T,
  customName?: string
): QueryStateItem<T> {
  return {
    toString,
    fromString,
    customName
  };
}

export function stringQueryStateItem(defaultValue = "") {
  return queryStateItem<string>(
    v => (v === defaultValue ? undefined : v),
    q => (q?.length ? q : defaultValue)
  );
}

type QueryStateConfigItemType<T> = T extends QueryStateItem<infer I>
  ? I
  : never;

type QueryStateConfig = {
  [key: string]: QueryStateItem<any>;
};

function getQueryFromRouter<T extends QueryStateConfig>(
  config: T,
  router: NextRouter
): TypeFromQueryStateConfig<T> {
  return Object.fromEntries(
    Object.entries(config).map(([key, { fromString, customName }]) => {
      return [key, fromString(queryValueAsString(customName ?? key, router))];
    })
  ) as any;
}

export type ValueSetter<T extends QueryStateConfig> = A.Compute<
  (
    value: Partial<
      {
        [K in keyof T]: QueryStateConfigItemType<T[K]>;
      }
    >
  ) => void
>;

export type TypeFromQueryStateConfig<T extends QueryStateConfig> = A.Compute<
  {
    [K in keyof T]: QueryStateConfigItemType<T[K]>;
  }
>;

export function useQueryState<T extends QueryStateConfig>(
  config: T
): [TypeFromQueryStateConfig<T>, ValueSetter<T>] {
  const queryRef = useRef(getQueryFromRouter(config, Router));
  const [queryState, setQueryState] = useState(queryRef.current);

  const valueSetter = useCallback<ValueSetter<T>>(
    value => {
      const fullValue = {
        ...queryRef.current,
        ...value
      };
      if (!deepEqual(fullValue, queryRef.current, { strict: true })) {
        const query = Object.fromEntries(
          Object.entries(fullValue)
            .map(([key, val]) => {
              return [config[key].customName ?? key, config[key].toString(val)];
            })
            .filter(([, val]) => !!val?.length)
        );
        const usedKeys = Object.entries(config).map(
          ([key, item]) => item.customName ?? key
        );
        const uncontrolledQuery = omit(usedKeys, Router.query);
        Router.push({
          pathname: Router.pathname,
          query: {
            ...uncontrolledQuery,
            ...query
          }
        });
      }
    },
    [config]
  );

  useEffect(() => {
    const handler = () => {
      const newQuery = getQueryFromRouter(config, Router);
      if (!deepEqual(newQuery, queryRef.current, { strict: true })) {
        queryRef.current = newQuery;
        setQueryState(newQuery);
      }
    };
    Router.events.on("routeChangeComplete", handler);
    return () => Router.events.off("routeChangeComplete", handler);
  }, [config]);

  return [queryState, valueSetter];
}
